import React from 'react';
import "./skills.css";
import AboutData from './AboutData';
// import Frontend from './Frontend';
// import Backend from './Backend';

const Skills = () => {
    return (
        <section className="skills section" id="skills" style={{fontSmooth: "antialiased"}}>
            <AboutData />
        </section>
    )
}

export default Skills